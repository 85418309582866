<template>
  <div class="">
    <div class="banner">
      <img src="@/assets/images/banner.png" />
    </div>
    <div class="news-list-wrap">
      <div
        class="news-list-item"
        v-for="item in dataList"
        :key="item.id"
        @click="toDetail(item)"
      >
        <div class="news-pic">
          <img :src="item.cover" />
        </div>
        <div class="news-title">
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="page-box" v-if="totalPages > 1">
      <button @click="prePage" :disabled="page == 1">上一页</button>
      <div>{{ page }}</div>
      <button @click="nextPage" :disabled="page == totalPages">下一页</button>
    </div>
    <div class="detail-share">
      <img
        src="@/assets/images/icon-share.svg"
        class="icon-share"
        @click="toShare"
      />
      分享
    </div>
    <van-dialog
      v-model="showQrcode"
      :show-confirm-button="false"
      close-on-click-overlay
      title=""
    >
      <div class="qrcode-container">
        <img :src="qrcodeSrc" alt="二维码图片" />
        <div class="share-title">分享功能操作说明</div>
        <div class="share-desc">第一步：点击长按识别二维码</div>
        <div class="share-desc">第二步：点击页面右上角“...”，分享专题</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import { list, ticket } from "@/network/API.js";
import wx from "weixin-js-sdk";
import QRCode from "qrcode";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      offest: 130,
      loading: false,
      finished: false,
      refreshing: false,
      page: 1, //当前页
      isLoading: false,
      dataList: [],
      total: 0, //列表总数
      totalPages: 0, //总数
      pageSize: 10,
      showQrcode: false,
      qrcodeSrc: "",
    };
  },
  async created() {
    this.qrcodeSrc = await QRCode.toDataURL(
      window.location.origin + window.location.pathname
    );
    this.getList();
    this.shareInit();
  },
  methods: {
    toShare() {
      this.showQrcode = !this.showQrcode;
    },
    prePage() {
      if (this.page == 1) {
        return;
      }
      this.page -= 1;
      this.getList();
    },
    nextPage() {
      if (this.page == this.totalPages) {
        return;
      }
      this.page += 1;
      this.getList();
    },
    onLoad() {
      this.page = this.page + 1;
      this.getList();
    },
    onRefresh() {
      //数据开始加载
      this.finished = false;
      this.loading = true;
      this.page = 1;
      this.getList();
    },
    async getList() {
      this.loading = true;
      const res = await list({
        type_id: 0,
        page_size: this.pageSize,
        page: this.page,
      });
      // this.isLoading = false;
      // this.loading = false;
      // this.refreshing = false; //主要原因在这

      this.total = parseInt(res.total);

      let num =
        this.total % this.pageSize == 0
          ? this.total / this.pageSize
          : parseInt(this.total / this.pageSize) + 1;
      this.totalPages = num > 0 ? num : 1;
      this.dataList = res.list;
      // if (this.page == 1) {
      //   this.dataList = res.list;
      // } else {
      //   this.dataList = this.dataList.concat(res.list);
      // }
      // this.finished = this.total <= this.dataList.length;
    },
    toDetail(item) {
      this.$router.push({
        name: "Detail",
        params: {
          aid: item.identity,
        },
      });
    },
    async shareInit() {
      let res = await ticket({
        url: window.location.origin + window.location.pathname,
      });
      let that = this;
      wx.config({
        debug: false,
        nonceStr: res.noncestr,
        appId: "wx3f9f67027db9ffaf",
        signature: res.signature,
        timestamp: res.timestamp,
        jsApiList: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "onMenuShareAppMessage",
          "onMenuShareTimeline",
        ],
      });
      wx.checkJsApi({
        jsApiList: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "onMenuShareAppMessage",
          "onMenuShareTimeline",
        ],
        success: (res) => {},
        fail: (err) => {},
      });
      wx.ready(() => {
        // this.canShare = true;
        wx.updateAppMessageShareData({
          title: "石家庄市红十字会新闻集结站",
          desc: "",
          link: window.location.origin + window.location.pathname,
          imgUrl:
            "https://hszh-admin.smartzhengwu.cn/vendor/dcat-admin/images/logo.png",
          success: (res) => {
            // alert("update success");
          },
          fail: (err) => {},
        });
        wx.updateTimelineShareData({
          title: "石家庄市红十字会新闻集结站",
          desc: "",
          link: window.location.origin + window.location.pathname,
          imgUrl:
            "https://hszh-admin.smartzhengwu.cn/vendor/dcat-admin/images/logo.png",
          success: (res) => {
            // alert("update success");
          },
          fail: (err) => {},
        });
      });
      wx.error((err) => {});
    },
  },
};
</script>
