import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/style.css'
import 'vant/lib/index.css';

Vue.config.productionTip = false

import {
  List,
  Loading,
  Toast,
  PullRefresh,
  Dialog
} from "vant";
Toast.setDefaultOptions({
  duration: 1500
});

Vue.use(Toast);
Vue.use(List)
  .use(Loading)
  .use(Dialog)
  .use(PullRefresh);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')